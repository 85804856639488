h1 {
  font-size: 74px;
}

.logo {
  width: 120px;
  height: 40px;
  float: left;
}
.site-layout .site-layout-background {
  background: #4a2040;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #727272;
}
