.xpa-top-header--logo-room {
  max-height: 2.5rem;
}

.xpa-d-flex {
	display: flex;
	justify-content: space-between;
}

.xpa-footer {
	padding: 0.5rem 1rem;
}

.xpa-footer a {
	color: #5558AF;
	text-decoration: none;
	background-color: transparent;
}

.xpa-footer a:hover {
	color: #224abe;
	text-decoration: underline;
}